/* .progressbar>div {
    display: grid;
    place-items: flex-start;
} */

.progressbar>div {
    font-size: 14px;
    width: 20%;
    float: left;
    position: relative;
    font-weight: 400;
    text-align: center;
}

.progressbar>div:before {
    width: 36px;
    height: 36px;
    line-height: 30px;
    display: block;
    font-size: 16px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px
}

.progressbar>div:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 22px;
    z-index: -1
}

.progressbar>div:first-child::after {
    width: 50%;
    left: 50%;

}


/* .progressbar>div:first-child {
    width: 100%;
    left: 0%;
} */
/* 
.progressbar>div:last-child {
    display: grid;
    place-items: flex-end;
} */

.progressbar>div:last-child::after {
    width: 50%;
    right: 50%;

}